<template>
  <div>
    <div class="notification-wrapper">
      <div :class="{ 'blue-dot': notification.isUnread }" />
      <div class="pfp">
        <user-avatar
          :size="30"
          :user="(notification.byUser)"
        />
      </div>
      <div class="content">
        <div class="header">
          <div class="flex items-left space-x-2">
            {{ notification.createdAt | short_date }}
          </div>
          <div class="date">
            {{ notification.createdAt | time }}
          </div>
        </div>
        <CampaignFileCard
          v-if="notification.campaignFileCommentId"
          :notification="notification"
          @nav="handleNavToCampaignFileComment"
        />
        <MediaAssetCard
          v-else-if="notification.mediaAssetId"
          :notification="notification"
          @nav="handleNavToMediaAssetComment"
        />
        <ContactCard
          v-else-if="notification.contactId"
          :notification="notification"
          @nav="handleNavToContactComment"
        />
        <RequestForProposalCard
          v-else-if="notification.requestForProposalId"
          :notification="notification"
          @nav="handleNavToRequestForProposalComment"
        />
        <CampaignCard
          v-else
          :notification="notification"
          @nav="handleNavToCampaignComment"
        />
      </div>
    </div>
    <div class="comment-wrapper">
      <div
        :class="isExpanded ? 'expandable-box-show' : 'expandable-box'"
      >
        <p
          ref="commentBody"
          class="commentBody"
        >
          &#8220;{{ extractTextFromHtml(notification.commentBody) }}&#8221;
        </p>
      </div>
      <div
        v-if="showExpandButton"
        class="expand-button"
        @click="toggleExpansion"
      >
        <condense-icon
          v-if="isExpanded"
          w="28"
          h="24"
        />
        <expand-icon
          v-else
          w="28"
          h="24"
        />
      </div>
    </div>
    <div
      v-if="showGroups"
      class="group-wrapper"
    >
      {{ notification.groupName }}
    </div>
  </div>
</template>
<script>
import { ExpandIcon, CondenseIcon } from '@/components/icons'
import { CampaignCard, CampaignFileCard, MediaAssetCard, ContactCard, RequestForProposalCard } from './CommentNotificationCards'
import CommentTaskNavigtion from '@/mixins/CommentTaskNavigation'

export default {
  components: {
    RequestForProposalCard,
    ExpandIcon,
    CondenseIcon,
    CampaignCard,
    CampaignFileCard,
    MediaAssetCard,
    ContactCard
  },
  mixins: [
    CommentTaskNavigtion
  ],
  props: {
    notification: {
      type: Object,
      required: false,
      default: null
    },
    showGroups: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isExpanded: false,
      showExpandButton: false
    }
  },
  computed: {
    companyLogoURL: {
      get () {
        return this.$store.getters.getCompanyLogoURL
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      const numLines = this.calculateNumLines()
      this.showExpandButton = numLines > 1
    })
  },
  methods: {
    toggleExpansion () {
      this.isExpanded = !this.isExpanded
    },
    extractTextFromHtml (htmlString) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(htmlString, 'text/html')
      const text = doc.body.textContent
      return text
    },
    handleNavToCampaignComment (obj) {
      this.goToCampaignComment(obj)
      this.$emit('close')
    },
    handleNavToCampaignFileComment (obj) {
      this.goToCampaignFileComment(obj)
      this.$emit('close')
    },
    handleNavToMediaAssetComment (obj) {
      this.goToMediaAssetComment(obj)
      this.$emit('close')
    },
    handleNavToContactComment (obj) {
      this.goToContactComment(obj)
      this.$emit('close')
    },
    handleNavToRequestForProposalComment (obj) {
      this.goToRequestForProposalComment(obj)
      this.$emit('close')
    },

    calculateNumLines () {
      const commentBody = this.$refs.commentBody
      if (commentBody) {
        const textHeight = commentBody.offsetHeight
        const lineHeight = parseFloat(window.getComputedStyle(commentBody).lineHeight)
        const numLines = Math.ceil(textHeight / lineHeight)
        return numLines
      }
      return 0
    }
  }
}
</script>

<style scoped>
.notification-wrapper{
  display: flex;
  padding-top:20px;
}
.pfp{
  height: auto;
  width: auto;
  margin-right:4%;
  border-radius: 50%;
}
.content{
  flex-grow:1;
}
.header{
  display:flex;
  justify-content: space-between;
  color: rgb(97, 91, 91);
}

.space-x-2 {
  column-gap: 0.5rem;
}
.text{
  padding: 10px 0 10px 0 ;
  max-width: 415px;
  line-height: 1.5;
}
.expandable-box {
  height: 33px;
  overflow: hidden;
  padding: 7px 22px 10px 10px;
  font-style: italic;
  line-height: 1.5;
}

.expandable-box-show {
  height: auto;
  overflow: hidden;
  padding: 10px 22px 10px 10px;
  font-style: italic;
  line-height: 1.5;

}
.comment-wrapper{
  position: relative;
  left: 10%;
  margin-bottom: 10px;
  width: 90%;
  background-color: rgb(232, 232, 232);
}
.expand-button{
  position: absolute;
  top: 5px;
  left: 92%;
}
.user-name{
  font-weight: 600;
}
.blue-dot {
  min-width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: 10px;
  margin-right: 8px;
  background-color: #2e76bc;
}
.campaign-name{
  cursor: pointer;
}
.commentBody{
  line-height: 1.7;
}
.group-wrapper{
  display:flex;
  justify-content: space-between;
  color: rgb(97, 91, 91);
  font-style: italic;
  padding: 7px 22px 10px 40px;
  font-size: 13px;
}
</style>
