import Vue from 'vue'

function create (campaignId, data = {}) {
  const path = `/campaigns/${campaignId}/renew`
  return Vue.api.post(path, { data })
}

export default {
  create
}
