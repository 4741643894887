export const ActivityIcon = () => import('./ActivityIcon.vue')
export const AdviseMeIcon = () => import('./AdviseMeIcon.vue')
export const AgenciesIcon = () => import('./AgenciesIcon.vue')
export const AssetsIcon = () => import('./AssetsIcon.vue')
export const CampaignsIcon = () => import('./CampaignsIcon.vue')
export const ClientIcon = () => import('./ClientIcon.vue')
export const ContactsIcon = () => import('./ContactsIcon.vue')
export const CreateIcon = () => import('./CreateIcon.vue')
export const HomeIcon = () => import('./HomeIcon.vue')
export const InfoIcon = () => import('./InfoIcon.vue')
export const LogOutIcon = () => import('./LogOutIcon.vue')
export const NotificationsIcon = () => import('./NotificationsIcon.vue')
export const ProductsIcon = () => import('./ProductsIcon.vue')
export const QuickOrderIcon = () => import('./QuickOrderIcon.vue')
export const ReportingIcon = () => import('./ReportingIcon.vue')
export const RequestForProposalIcon = () => import('./RequestForProposalIcon.vue')
export const SettingsIcon = () => import('./SettingsIcon.vue')
export const SupportIcon = () => import('./SupportIcon.vue')
export const TaskIcon = () => import('./TaskIcon.vue')
export const WarningIcon = () => import('./WarningIcon.vue')
export const WorkspaceIcon = () => import('./WorkspaceIcon.vue')
