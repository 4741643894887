<template>
  <component
    :is="`${name}Icon`"
    v-bind="$props"
  />
</template>

<script>
import * as iconComponents from './UiIcons'
import icons from '../../../maps/icons'

export default {
  name: 'UiIcon',
  components: { ...iconComponents },
  props: {
    name: {
      type: String,
      required: true,
      validator: function (value) {
        return icons.items.indexOf(value) !== -1
      }
    },
    state: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'success', 'error', 'warning'].indexOf(value) !== -1
      }
    },
    size: {
      type: String,
      default: 'small',
      validator: function (value) {
        return ['xsmall', 'small', 'medium', 'large', 'xlarge'].indexOf(value) !== -1
      }
    }
  }
}
</script>
