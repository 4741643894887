import Vue from 'vue'
import { pick, omit } from 'lodash-es'
import files from './files'
import comments from './comments'

function updateStoreList (response) {
  Vue.$store.state.RequestForProposal.list = response
  return response
}

function updateStoreRequestForProposalList (id) {
  return function (response) {
    const requestForProposals = [...Vue.$store.state.RequestForProposal.list.data]
    const idx = requestForProposals.findIndex((requestForProposal) => requestForProposal.id === id)
    requestForProposals[idx] = response
    Vue.$store.state.RequestForProposal.list.data = requestForProposals
    return response
  }
}

function updateStoreRequestForProposal (response) {
  Vue.$store.state.RequestForProposal.requestForProposal = response
  return response
}

function create (params = {}, data) {
  return Vue.api.post('/request-for-proposals', { params, data })
}

function list (params = {}) {
  return Vue.api.get('/request-for-proposals', { params })
    .then(updateStoreList)
}

function load (params = {}) {
  const { id } = params
  params = pick(params, ['companyId', 'companyGroupId'])
  return Vue.api
    .get(`/request-for-proposals/${id}`, { params })
    .then(updateStoreRequestForProposalList(id))
    .then(updateStoreRequestForProposal)
}

function update (params = {}) {
  const { id } = params
  const data = omit(params, ['id', 'companyId', 'companyGroupId'])
  params = pick(params, ['companyId', 'companyGroupId'])
  return Vue.api
    .patch(`/request-for-proposals/${id}`, { params, data })
    .then(updateStoreRequestForProposalList(id))
}

function _delete (params = {}) {
  const { id } = params
  params = pick(params, ['companyId', 'companyGroupId'])
  return Vue.api.delete(`/request-for-proposals/${id}`, { params })
}

export default {
  create,
  list,
  load,
  update,
  delete: _delete,
  files,
  comments
}
