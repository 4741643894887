export default {
  items: [
    'activity',
    'adviseMe',
    'agencies',
    'assets',
    'campaigns',
    'client',
    'contacts',
    'create',
    'home',
    'info',
    'logOut',
    'notifications',
    'products',
    'quickOrder',
    'reporting',
    'requestForProposal',
    'settings',
    'support',
    'task',
    'warning',
    'workspace'
  ]
}
