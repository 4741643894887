import Vue from 'vue'
import schedules from './schedules'
import versions from './versions'
import products from './products'
import renewals from './renewals'

function create (data = {}) {
  const path = '/campaigns'
  return Vue.api.post(path, data)
}

function update (campaignId, data = {}) {
  const path = `/campaigns/${campaignId}`
  return Vue.api.patch(path, { data })
}

export default {
  schedules,
  versions,
  products,
  renewals,
  create,
  update
}
